@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.banner-btn {
  border-radius: 6px;
  background: #0077d8;
  height: 52px;
  padding: 0px 30px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border: none;
  margin-top: 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.signInError {
  color: #ea2121;
  font-size: 12px;
  font-weight: 300;
}

.signup-error {
  color: red;
  text-align: center;
  font-weight: 300 !important;
  font-size: 12px !important;
  margin-bottom: 5px;
}
.fa-eye-slash,
.fa-eye {
  color: #9b9b9b;
}
.active-star {
  color: orange;
}
.inactive-star {
  color: rgb(209, 209, 209);
}
.fa-long-arrow-right {
  margin-left: 10px;
}

button.slick-next:before {
  content: url("../public/assets/arrow_next_right.svg") !important;
}
button.slick-prev:before {
  content: url("../public/assets/arrow_next_left.svg") !important;
}
.slick-prev {
  left: -40px !important;
}

.slick-dots {
  @media (max-width: 767px) {
    bottom: -40px !important;
  }
}
