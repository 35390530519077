.navbar-wrapper {
  color: #000;
  padding: 10px 0px;
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: #fff;
  box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.1);
  .navbar-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1023px) {
      width: 100%;
    }

    .fa-bars {
      display: none;
      @media (max-width: 1023px) {
        display: block;
        font-size: 24px;
      }
    }
    .fa-times {
      display: none;
      @media (max-width: 1023px) {
        display: block;
        font-size: 24px;
      }
    }
    .responsivenavbar-wrapper {
      display: none;
      @media (max-width: 1023px) {
        display: block;
        background-color: #fff;
        color: #000;
        height: 100vh;
        left: -400px;
        padding: 10px 30px;
        position: fixed;
        top: 0;
        transition: all 0.5s ease;
        width: 400px;
        z-index: 5;
      }

      .navbar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .responsive-list {
        display: block !important;
        .responsive-item {
          padding: 10px 0px !important;
        }
        .responsive-login-btn {
          margin-left: 0px !important;
          margin-top: 20px;
        }
      }
    }
    .active-navbar {
      @media (max-width: 1023px) {
        left: 0px;
        box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.1);
      }
      @media (max-width: 481px) {
        width: 100%;
      }
    }

    .navbar-list {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      @media (max-width: 1023px) {
        display: none;
      }
      .navbar-item {
        padding: 0px 25px;
        @media (max-width: 1199px) {
          padding: 0px 15px;
        }
        .navbar-link {
          color: #112031;
          font-size: 16px;
          font-weight: 300;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            color: #0077d8;
          }
        }
        .active {
          color: #0077d8;
          font-weight: 500;
        }
      }
      .login-btn {
        border-radius: 6px;
        background: #0077d8;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        padding: 10px 20px;
        margin-left: 30px;
        border: none;

        @media (max-width: 1023px) {
          margin-left: 0px;
        }
        span {
          margin-left: 10px;
        }
      }
    }

    .download-icons {
      @media (max-width: 1023px) {
        display: none;
      }
      .logout_btn {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background: #3192fe;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
      }
    }
    .re-download-icons {
      margin-top: 20px;
      @media (max-width: 481px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        img {
          margin-left: 0px !important;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.boxShadowUnsert {
  box-shadow: unset !important;
}
.translateCom{
  display: flex;
  gap: 15px;
}