.footer-wrapper {
  padding: 70px 0px 50px 0px;
  background: rgb(44, 145, 255);
  background: linear-gradient(
    90deg,
    rgba(44, 145, 255, 0.14049369747899154) 0%,
    rgba(44, 145, 255, 0.06206232492997199) 51%,
    rgba(44, 145, 255, 0.11248249299719892) 100%
  );
  position: relative;
  .footer-main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .footer-logo {
      width: 200px;
    }
    .footer-menu-list {
      list-style: none;
      margin: 20px 0px;
      padding: 0;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        flex-direction: column;
      }
      .menu-item {
        padding: 0px 30px;
        @media (max-width: 1023px) {
          padding: 0px 15px;
        }

        @media (max-width: 767px) {
          padding: 5px 0px;
        }
        .menu-link {
          color: #112031;
          font-size: 16px;
          font-weight: 300;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            color: #0077d8;
          }
        }
      }
    }
    .social-icons-list {
      margin-top: 20px;
      img {
        margin: 0px 5px;
      }
    }
  }
  .first-arrow {
    position: absolute;
    bottom: 30px;
    right: 50px;
    @media (max-width: 1199px) {
      width: 40px;
      bottom: 30px;
      right: 60px;
    }
    @media (max-width: 481px) {
      width: 40px;
      bottom: 30px;
      right: 20px;
    }
  }
  .second-arrow {
    position: absolute;
    top: 50px;
    left: 50px;

    @media (max-width: 1199px) {
      width: 40px;
      top: 30px;
      left: 60px;
    }
    @media (max-width: 481px) {
      width: 40px;
      top: 30px;
      left: 20px;
    }
  }
}
.copyright-footer {
  text-align: center;
  padding: 10px 20px;
  background-color: #2c91ff;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}
