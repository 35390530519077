.language {
  position: relative;
  // border: "2px solid black";

  .translate-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    position: relative;
    select.goog-te-combo {
      width: 100%;
      height: 100%;
      border: none;
      font-size: 16px;
      color: #000;
    }
    select {
      height: 25px;
      border: none;
  outline: none;
    }
    span {
      position: absolute;
      z-index: -2;
      display: none;
    }
  }
}
.skiptranslate
{
  iframe{
  display: none;
  visibility: hidden;
  }
  margin-bottom: 0;
}
.translate-box{
  
  .goog-te-gadget{
      color: transparent;
      font-size: 0;
      border: 2px #112031 solid;
      border-radius: 5% 5% 5% 5%;
      height: 40px;
      padding-top: 5px;
  }
}