.bg_wrapper_popup_new {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #000000a1;
  top: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  .card_payment_popup {
    width: 800px !important;
    .pay_method {
      background-color: #fff;
      .pay_head {
        font-size: 20px;
        font-weight: 600;
        color: #353f5e;
      }
      .pay_para {
        font-size: 16px;
        color: #353f5e;
        line-height: 30px;
        margin-top: 20px;
      }
      .payment-card-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        li {
          margin-right: 10px;
        }
      }
      .pay_input_div {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;

        .input_div {
          display: flex;
          flex-direction: column;
          width: 50%;
          color: #646363;
          margin-bottom: 20px;
          @media (max-width: 767px) {
            width: 100%;
          }
          label {
            margin-bottom: 5px !important;
          }
          .form-wrapperInput {
            width: 90%;
            height: 42px;
            padding: 8px 16px;
            border: 1px solid #e6e6e6;
            margin-bottom: 5px;
            outline: none;
            @media (max-width: 481px) {
              width: 100%;
            }
            -webkit-appearance: none;
            appearance: none;
            border-radius: 5px;

            &::placeholder {
              font-size: 14px;
              color: #7b7b7b;
            }
          }
        }
        .proceed-btn {
          padding: 12px 40px;
          outline: none;
          border: none;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 500;
          background-color: #2c91ff;
          color: #fff;
          margin-top: 10px;
          @media (max-width: 481px) {
            width: 100%;
          }
        }

        .proceed-btn .loader-spinner .loading-gif {
          animation-name: spin;
          animation-duration: 2000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
    }

    .view-user-list {
      margin-top: 20px;
      .user-list-item {
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
        background: #dcedff;
        border-bottom: 1px solid #d9d9d9;
        .srno-item {
          width: 25%;
        }
        .username {
          width: 25%;
        }
        .usermobile {
          width: 25%;
        }
        .scandate {
          width: 25%;
          text-align: right;
        }
      }
      .user-list-name {
        background: #fff;
      }
    }
  }

  .delete_popup {
    width: 380px !important;
  }
  .add_business_popup {
    height: 700px;
    overflow-y: scroll;
  }

  .popup_box_bpn {
    width: 600px;
    padding: 10px 30px;
    border-radius: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    margin: 0 10px;

    @media (max-width: 481px) {
      padding: 10px 20px;
    }
    .popup_header {
      padding: 15px 0px;
      position: relative;
      .p_header_hding {
        text-align: center;
        color: #1d2c3d;
        font-size: 20px;
        font-weight: 600;
      }
      .view_user_list_head {
        text-align: left;
      }
      .close_pp_btn {
        position: absolute;
        top: 0;
        right: -10px;
        cursor: pointer;
        i {
          color: #1d2c3d;
          font-size: 20px;
        }
      }
    }

    .delete-popup-main {
      text-align: center;
      .delete-icon {
        width: 80px;
        height: 80px;
        border: 1px solid red;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
      }
      .delete-title {
        color: #000;
        font-size: 18px;
        font-weight: 500;
        text-transform: capitalize;
        margin: 25px 0;
      }
      .delete-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .yes-btn {
          border-radius: 100px;
          background-color: #db2020;
          color: #fff;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          border: none;
          width: 100px;
          padding: 8px;
        }
        .no-btn {
          border-radius: 100px;
          border: 2px solid #bdbdbd;
          background-color: #fff;
          color: #979797;
          font-size: 18px;
          font-weight: 400;
          text-transform: capitalize;
          padding: 7px 30px;
        }
      }
    }
    .input-list {
      margin-top: 20px;
      label {
        color: #1d2c3d;
        font-size: 16px;
        font-weight: 500;
      }
      .input-item {
        border-bottom: 2px solid #d9d9d9;
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
        p {
          margin-bottom: 0px !important;
          padding: 10px 0;
        }
        input {
          border: none;
          outline: none;
          padding: 10px 0px;
          width: 100%;
          &::placeholder {
            color: #1d2c3d;
            font-size: 14px;
            font-weight: 300;
          }
        }
        .inputChange-color {
          padding: 0px 0px;
          width: 30px;
          height: 30px;
        }
        select {
          border: none;
          outline: none;
          padding: 10px 0px;
          width: 100%;
          font-size: 14px;
          &::placeholder {
            color: #1d2c3d;
            font-size: 14px;
            font-weight: 300;
          }
        }
      }
    }
    .login-btn {
      border-radius: 200px;
      background: #3192fe;
      width: 100%;
      height: 48px;
      flex-shrink: 0;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      border: none;
      margin-top: 40px;
      text-transform: uppercase;
    }
    .upload-business-logo {
      margin-top: 20px;
      h6 {
        color: #1d2c3d;
        font-size: 16px;
        font-weight: 500;
      }
      .business_logo {
        border-radius: 6px;
        border: 1px solid #e2e2e2;
        background: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        padding: 20px;
        input {
          display: none;
        }
        .camera-image {
          width: 40px;
          height: 40.667px;
          background-color: #d5d5d5;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .camerashowImage {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }
        span {
          color: #1d2c3d;
          font-size: 16px;
          font-weight: 500;
          margin-top: 5px;
        }
      }
    }
  }

  .qrcode_popup {
    width: 700px;
    @media (max-width: 1023px) {
      width: 700px;
    }
    @media (max-width: 767px) {
      width: 100%;
    }

    .form-inputphone {
      width: 50%;
      @media (max-width: 767px) {
        width: 100% !important;
      }
    }
    .iphone-backgroundimage {
      width: 300px;
      // height: 480px;
    }

    .mobile_remove {
      width: 50%;
      position: relative;
      @media (max-width: 767px) {
        display: none;
      }
    }
    .iphone-container {
      height: 419px;
      width: 261px;
      position: absolute;
      top: 119px;
      left: 43px;
      .reward-back {
        background-color: #e1c8a0;
        width: 100%;
        // padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .reward-back-item {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid orange;
          background-color: #a97b50;
          color: #fff;
        }
        .reward-active {
          opacity: 0.5;
        }
      }
      .reward-info {
        padding: 1rem;
        p {
          font-size: 10px;
          margin-bottom: 0;
        }
      }
      .rewardqrcode-text {
        h6 {
          text-align: center;
          font-size: 9pt;
          padding: 0px;
          margin: 0px;
          color: #ff6d00;
        }
      }
    }
  }
}

.error_popup {
  position: fixed;
  z-index: 99999;
  top: 5px;
  max-width: 700px;
  right: 50%;
  padding: 15px 8px;
  transform: translate(50%, 0);
  min-width: 350px;
  background-color: #e74c3c;
  color: #fff;
  border-radius: 5px;
  animation-name: error_comes;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  .error_container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .message {
    display: flex;
    height: 100%;
    align-items: center;
    gap: 10px;
    word-wrap: break-word;
    white-space: pre-line;
  }
  .close_pp_btn {
    float: right;
  }
  .close_pp_btn {
    svg {
      fill: #fff !important;
    }
  }
  .error_progress {
    width: 100%;
    height: 5px;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    animation-name: errorProgress;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
  }
}

@keyframes errorProgress {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

@keyframes error_comes {
  0% {
    transform: translate(50%, -100%);
  }
  100% {
    transform: translate(50%, 0);
  }
}

@media screen and (max-width: 360px) {
  .error_popup {
    padding: 16px 3px;
    transform: translate(50%, 0);
    min-width: 293px;
  }
}

.thankyou-main {
  width: 100%;
  background-color: #fff;
  text-align: center;

  .thankyou-heading {
    color: #000;
    font-size: 50px;
    font-weight: 700;
    margin-top: 30px;
    @media (max-width: 481px) {
      font-size: 40px;
    }
  }
  .thankyou-para {
    color: #2f3641;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-transform: capitalize;
    margin-bottom: 30px;
  }
  .dashboard-btn {
    width: 100%;
    height: 56px;
    border-radius: 6px;
    background: #2c91ff;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border: none;
    margin-top: 20px;
  }
}

.success_popup {
  max-width: 400px !important;
  min-width: 300px;
  position: relative;
  top: 0;

  .success_heading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -15px;
    color: #6d2ef1;
    font-weight: 600;
    margin-top: 30px;
    flex-direction: column;
  }

  .sucess_message {
    display: flex;
    justify-content: center;
    font-weight: 400;
    color: #000;
  }
  .sucess_message > div {
    text-align: center;
  }
  .success_popup_btn {
    display: flex;
    justify-content: center;
  }
  .success_popup_btn {
    button {
      border-radius: 6px;
      padding: 10px 30px;
      background: #0077d8;
      color: #fff;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      border: none;
      margin-top: 30px;
    }
  }
}
